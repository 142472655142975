import React from "react";
import { Route, Switch } from "react-router-dom";
import Admin from "./containers/Admin";
import Login from "./containers/Login";
//import Signup from "./containers/Signup";
import Qr from "./containers/Qr";

import AppliedRoute from "./components/AppliedRoute";

export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={Qr} props={childProps} />
    <AppliedRoute path="/login" exact component={Login} props={childProps} />
    {childProps.isAuthenticated?
      <AppliedRoute path="/admin" exact component={Admin} props={childProps} />
    :
      ""
    }

    { /* Finally, catch all unmatched routes */ }
    <Route component={Qr} props={childProps} />
  </Switch>;
