
import { API } from "aws-amplify";

const BASE =  'https://ap2bc3nuw9.execute-api.us-east-1.amazonaws.com/dev';

export const parse_userId = (url) => {
    console.log(url);
    const arr = url.split('/');
    return arr.reverse()[0];
}

export const parse_www = (url) => {
    //const dni = new URLSearchParams(new URL(url).search).get('dni');
    //console.log("DNI {dni}" + dni);
    const arr = url.split('.');
    if (arr[0] === 'wwww')
        return arr[1];

    return arr[0];
}

export const ORG_UNIT = parse_www(window.location.hostname);

export const image_path = (e) => "/bg/"+e+".png";



/******************************
*  Metodos de API
*******************************/

export const AddItem = (obj) => {

    //return fetch(BASE + '/users/');
    return API.post('credentials','/users/', {body: obj});
}

export const GetList = () => {

    //return fetch(BASE + '/users/');
    return API.get('credentials','/users/');
}


export const GetItem = (id) => {

    //return await fetch(BASE + '/users/'+id);
    return API.get('credentials','/users/'+id);
}

export const UpdateItem = (id, obj) => {

    //return await fetch(BASE + '/users/'+id);
    return API.put('credentials','/users/'+id, {body: obj});
}

export const DelItem = (id) => {

    //return await fetch(BASE + '/users/'+id);
    return API.del('credentials','/users/'+id);
}

