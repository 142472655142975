import React, { Component } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Login.css";
import { Auth } from "aws-amplify";

import toaster from 'toasted-notes';
import 'toasted-notes/src/styles.css'; // optional styles


export default class Login extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      password: ""
    };
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      Auth.signIn(this.state.email, this.state.password)
        .then((e) => {
          console.log(e);
          this.props.userHasAuthenticated(true, e.username);
          this.props.history.push("/admin");
        })
        .catch((e) => {
          console.log("E: ",e.message);
          toaster.notify(<div style={{ color: "red" }}>Usuario incorreto</div>);
          this.setState({ isLoading: false });
        });

    } catch (e) {
      alert(e.message);
    }
    
  }

  render() {
    return (
      <div className="Login">
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="email" bsSize="large">
            <ControlLabel>Usuario</ControlLabel>
            <FormControl
              autoFocus
              type="text"
              value={this.state.email}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="password" bsSize="large">
            <ControlLabel>Contraseña</ControlLabel>
            <FormControl
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
            />
          </FormGroup>
          <LoaderButton
            block
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Ingresar"
            loadingText="Ingresando..."
          />
        </form>
      </div>
    );
  }
}
