import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { Nav, Navbar, NavItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { withAuthenticator } from 'aws-amplify-react'; 
import { Auth } from "aws-amplify";
import Routes from "./Routes";
import "./App.css";


class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      username: null,
    };

  }

  userHasAuthenticated = (authenticated, username = null) => {
    this.setState({ isAuthenticated: authenticated });
    this.setState({ username: username });
  }

  handleLogout = async event => {
    await Auth.signOut();
    this.userHasAuthenticated(false);
    this.props.history.push("/login");
  }

  componentDidMount = event => {
    try {
      Auth.currentSession();
      //this.userHasAuthenticated(true);

      Auth.currentAuthenticatedUser({
        bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      }).then(user => {
        this.userHasAuthenticated(true, user.username);
      })
        .catch(err => console.log("E:" + err));

    }
    catch (e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }

    this.setState({ isAuthenticating: false });
  }


  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
    };

    return (
      !this.state.isAuthenticating &&
      <div>
        <Navbar fluid collapseOnSelect>
          <Navbar.Header>
            <Navbar.Brand>
              <Link to="/">QRed</Link>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav pullRight>
              {this.state.isAuthenticated ?
                <Fragment>
                <NavItem href="/admin">  Admin </NavItem>
                <NavItem onClick={this.handleLogout}>  Salir </NavItem>
                </Fragment>
                :
                <Fragment>
                  <LinkContainer to="/login">
                    <NavItem>Ingresar</NavItem>
                  </LinkContainer>
                </Fragment>
              }
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Routes childProps={childProps} />
      </div>
    );
  }
}

export default (withRouter(App));