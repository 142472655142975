export default {
  s3: {
    REGION: "YOUR_S3_UPLOADS_BUCKET_REGION",
    BUCKET: "YOUR_S3_UPLOADS_BUCKET_NAME"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://01zd8ojpfk.execute-api.us-east-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_eIzknLf42",
    APP_CLIENT_ID: "o7cg0jh0k1vveqiq9524o0e3r",
    IDENTITY_POOL_ID: "aaaus-east-1:c36ef369-6cd8-4f52-8ef3-60cb75dd0388"
  }
};
