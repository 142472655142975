import React, { Component } from "react";
import "./Admin.css";
import * as  db from '../DB';
import LoaderButton from "../components/LoaderButton";
//import { withAuthenticator } from 'aws-amplify-react'; 
//import { I18n } from 'aws-amplify';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

class Admin extends Component {
  constructor(props) {
      super(props);

      this.grid = React.createRef();

      this.state = {
        selectedItems: [],
        currentUser: {userId: '', name: '', obs: ''},
        columnDefs: [
            {headerName: 'dni', width: 100, field: 'userId', resizable: true},
            {headerName: 'Nombre', field: 'name', editable: true, resizable: true},
            {headerName: 'Descripcion', field: 'obs', editable: true, resizable: true},
        ],
      }

  }

  componentDidMount() {
    db.GetList()
        //.then(result => result.json())
        .then(rowData => this.setState({rowData}))
  }
  onCellValueChanged = (event) => {
    const field = event.colDef.field;
    const newVal = event.value;
    const userId = event.data.userId;

    console.log(field);
    console.log(newVal);
    console.log(userId);

    const user = event.data;
    user[field] = newVal; 
    console.log(user);

    
    db.UpdateItem(userId, user)
    .then( (response) =>  {
      console.log("OK");
      console.log(response);
      //console.log(this.grid);
      //this.gridApi.updateRowData({add: [user], addIndex:0});
      this.setState({isLoading: false});
    })
    .catch(function (error) {
      console.log("ERROR");
      console.log(error);
      alert("Puede que haya ocurrido un error al actualizar, verifique refrescando el listado");
      this.setState({isLoading: false});
    });
    

  }  
  handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    console.log(name);
    console.log(value);
    this.setState( { currentUser: {...this.state.currentUser, [name]: value }});
  }

  addElement = (event) => {
    event.preventDefault();
    this.setState({isLoading: true});
    const user = this.state.currentUser;
    console.log(user);

    db.AddItem(this.state.currentUser)
    .then( (response) =>  {
      console.log("OK");
      console.log(response);
      //console.log(this.grid);
      this.gridApi.updateRowData({add: [user], addIndex:0});
      this.setState({isLoading: false});
    })
    .catch(function (error) {
      console.log("ERROR");
      console.log(error);
      this.setState({isLoading: false});
    });
  }

  onQuickSearch = (event) => {
    this.setState({qSearch: event.value});

    const v = document.querySelector('#qSearch').value;
    console.log( v );
    console.log(this.grid.current.api);
    this.gridApi.setQuickFilter(v)
    
  }
  cancelSearch = (event) => {
      if (event.key === 'Escape') {
        document.querySelector('#qSearch').value = '';
        this.grid.current.api.setQuickFilter('');
      }
  }

  onSelectionChanged = (e) => {
      var selectedItems = this.gridApi.getSelectedRows();
      //console.log(selectedRows);
      this.setState({selectedItems})
  }

  onGridReady = (params) => {

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    //gridApi.setRowData(data);
  }

  onDeleteItems = () => {
    this.state.selectedItems.forEach( item => {
      db.DelItem(item.userId)
        .then(e => {
          this.gridApi.updateRowData({remove: [item]});;
        })
        .catch(e => {
          console.log(e)
          alert("Puede que haya ocurrido un error al eliminar, verifique refrescando el listado")
        });
    });
  }
  validateForm = () => {
     return this.state.currentUser.dni !== '';
  }
  validateDeleteForm = () => {
    return  this.state.selectedItems.length > 0;
  }

  render() {
    const tt = {
      backgroundColor: '#EEEEEE'
    }
      return (
          <div
              className="ag-theme-balham container"
              style={{ height: '500px', width: '100%' }}
          >
            <div >
            <form onSubmit={this.addElement} >
              <div className="row">
                <div className="col-md-2">
                  <label htmlFor="dni">DNI</label>
                  <input className="form-control" type="text" name="userId" value={this.state.currentUser.userId} onChange={this.handleInputChange} />
                </div>
                <div className="col-md-3">
                  <label htmlFor="name">Nombre</label>
                  <input className="form-control" type="text" name="name" value={this.state.currentUser.name} onChange={this.handleInputChange} />
                </div>
                <div className="col-md-4">
                <label htmlFor="obs">Observacion</label>
                  <input className="form-control" type="text" name="obs" value={this.state.currentUser.obs} onChange={this.handleInputChange} />
                </div>
                <div className="col-md-3">
                  <label htmlFor="obs">&nbsp;</label>
                  <LoaderButton
                    block
                    className="form-control btn-primary"
                    type="submit"
                    isLoading={this.state.isLoading}
                    text="Agregar"
                    disabled={!this.validateForm()}
                    loadingText="Agregando..."
                  />
                </div>
              </div>
            </form>            
          </div>


          <br />
            <div className="row">
            <div className="col-md-11">
                <input id="qSearch" type="text" className="form-control" placeholder="Buscar..." aria-label="Buscar..." aria-describedby="xx" 
                  name="qSearch" value={this.state.qSearch}
                  onChange={this.onQuickSearch}
                  onKeyDown={this.cancelSearch}
                  style={tt}
                />
              </div>
              <div className="col-md-1">
              <LoaderButton
                    block
                    className="form-control btn-danger"
                    type="button"
                    onClick={this.onDeleteItems}
                    isLoading={this.state.isLoading}
                    text="Eliminar"
                    disabled={!this.validateDeleteForm()}
                    loadingText="Eliminando..."
                  />
              </div>
            </div>

              <AgGridReact
                  ref={this.grid}
                  enableSorting={true}
                  enableFilter={true}
                  pagination={false}
                  columnDefs={this.state.columnDefs}
                  rowData={this.state.rowData}
                  onCellValueChanged={this.onCellValueChanged}
                  rowSelection='multiple'
                  onSelectionChanged={this.onSelectionChanged}
                  enableCellChangeFlash={true}
                  onGridReady={this.onGridReady}
                  __floatingFilter={true}
                  >
              </AgGridReact>
          </div>  
      );
  }
}

//export default withAuthenticator(Admin, {includeGreetings: true});
export default Admin;
