import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import QrReader from 'react-qr-reader';
import * as db from '../DB';
import "./Qr.css";

const SERV_QR = "http://www.codigos-qr.com/qr/php/qr_img.php?d=https%3A%2F%2Fsindicato_carne_gualeguay.qred.com.ar%2F{{userId}}&s=4&e=m";

class Qr extends Component {
  constructor(props) {
    super(props);

    this.state = {
      credential: null,
      verificando: true
    };
  }

  setUserId = (userId) => {
    this.setState( {userId: userId, credential: {userId: userId, name: ''}} );

    db.GetItem(userId)
      //.then(result => result.json())
      .then(data => {
          if (data.error) 
             throw Error('no valido');
          this.setState({userId:userId, credential: data, verificando: false});
        })
      .catch(error => {
          console.log(error);
          this.setState({userId:userId, credential: null, verificando: false });
      });

  }
  componentDidMount() {
    var userId = db.parse_userId(window.location.href);
    if(parseInt( userId )) {
      this.setUserId(userId)
    }
    else {this.setState({verificando: false})}
    
  }

  handleScan = data => {
    if (data) {
      var userId = db.parse_userId(data)
      if(parseInt( userId )) {
        this.setUserId(userId);
      }
    }
  }

  handleError = err => {
    console.error(err)
  }

  render_user() {
    if (this.state.credential) {
      return <div>
           <div id="div_userId"> {this.state.credential.userId}</div>
           <div id="div_name">{this.state.credential.name}</div>
           <div id="div_obs">EMPRESA EMPLEADORA <br/>{this.state.credential.obs}</div>
           <div><img alt="qr" src={SERV_QR.replace("{{userId}}", this.state.credential.userId)} /></div>
           <div><button onClick={() => this.setState({userId: null})}>Nueva credencial</button></div>
          </div>
   }else{
     return <div>
     <div id="div_userId"> DNI {this.state.userId}</div>
     <div id="div_name">NO VALIDO</div>
     <button onClick={() => this.setState({userId: null})}>Nueva credencial</button>
    </div>
   }    
  }

  render_data(){
    if( ! this.state.verificando )
    {
      if( this.state.userId )
        return this.render_user() ;
      else
        return this.render_qr();
    }
  }
  render_qr() {
    return (
      <div id="qr">
        <QrReader
          delay={300}
          onError={this.handleError}
          onScan={this.handleScan}
          showViewFinder={true}
          legacyMode={false}
          style={{ width: '100%' }}
        />
      </div>
    );
  }

  render(){
    return (
      <div className="row">
        <div className="col-md-6"><img id="logo" alt="credencial" src="logo/sind_carne_gualeguay.png"></img></div>
        <div className="col-md-6" id="data">{this.render_data()}</div>  
      </div>
    );
  }

}



export default withRouter(Qr);
